<template>
  <div class="inspect-tasks-action">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="添加巡检任务" />
    <ml-tip class="scroll-box" :showHeader="false" style="border: 0">
      <el-scrollbar :height="tabberHeight(185)">
        <!-- 表单 -->
        <ml-form
          ref="inspectTasksActionFormRef"
          labelWidth="200px"
          style="width: 1200px; margin: 20px auto 40px"
          :model="inspectTasksActionForm"
          :rules="inspectTasksActionFormRules"
        >
          <!-- 提示标题 -->
          <el-divider content-position="left" style="font-weight: bold">巡检任务信息</el-divider>
          <ml-input
            prop="taskName"
            label="巡检名称:"
            placeholder="请输入巡检名称"
            v-model.trim="inspectTasksActionForm.taskName"
          />
          <!-- 巡检计划 -->
          <ml-select
            prop="planId"
            placeholder="请选择巡检计划"
            :options="inspectPlansData"
            label="巡检计划:"
            keyName="planId"
            labelName="planName"
            valueName="planId"
            v-model="inspectTasksActionForm.planId"
            v-model:node="inspectTasksActionFormNode"
          />
          <!-- 日期 -->
          <ml-date-picker
            prop="date"
            startPlaceholder="开始日期"
            endPlaceholder="结束日期"
            label="日期："
            type="datetimerange"
            disabled
            v-model="inspectTasksActionForm.date"
          />
          <el-row>
            <el-col :span="20">
              <el-form-item label="负责人:" prop="managerUserVos">
                <div class="name-tag-box">
                  <el-tag
                    size="mini"
                    type="info"
                    v-for="item in inspectTasksActionForm.managerUserVos"
                    :key="item"
                  >
                    {{ item.userName }}
                  </el-tag>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <div style="padding: 0 10px">
                <!-- <el-button
                  :disabled="Object.keys(inspectTasksActionFormNode).length <= 0"
                  type="text"
                  @click="clickManagerUserVosAdd"
                  >添加</el-button
                > -->
              </div>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="20">
              <el-form-item label="执行人:" prop="inspectUserVos">
                <div class="name-tag-box">
                  <el-tag
                    size="mini"
                    type="info"
                    v-for="(item, index) in inspectTasksActionForm.inspectUserVos"
                    :key="item"
                    @close="clickTagCloseI(index)"
                  >
                    {{ item.userName }}
                  </el-tag>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <div style="padding: 0 10px">
                <el-button
                  :disabled="Object.keys(inspectTasksActionFormNode).length <= 0"
                  type="text"
                  @click="clickInspectUserVosAdd"
                  >添加</el-button
                >
              </div>
            </el-col>
          </el-row>

          <!-- 提交按钮 -->
          <ml-button
            submitText="提交"
            submitIcon="el-icon-upload2"
            cancelIcon="el-icon-circle-close"
            cancelText="返回上一页"
            @click-submit="clickSubmitInspectTasksAction"
            @click-cancel="clickCancelInspectTasksAction"
          />
        </ml-form>
      </el-scrollbar>
    </ml-tip>

    <!-- 添加人提示框 -->
    <ml-dialog
      width="1200px"
      ref="assignedPeopleDialogRef"
      :title="isMUser"
      @clickSubmit="clickSubmitAssignedPeople"
      @clickClose="clickSubmitAssignedPeopleClose"
      @before-close="beforeClose"
    >
      <template #body>
        <div>
          <div>
            <ml-form
              :style="{ width: '1180px' }"
              inline
              labelWidth="80px"
              :model="assignedPeopleForm"
              ref="assignedPeopleFormRef"
            >
              <!-- 帐号 -->
              <ml-input
                prop="userCodeLike"
                placeholder="请输入账号"
                label="帐号:"
                v-model="assignedPeopleForm.userCodeLike"
              />
              <!-- 用户 -->
              <ml-input
                prop="userNameLike"
                placeholder="请输入用户名"
                label="用户名:"
                v-model="assignedPeopleForm.userNameLike"
                :style="{ marginRight: '40px' }"
              />
              <!-- 搜索按钮 -->
              <ml-button
                submitText="搜索"
                submitIcon="el-icon-search"
                cancelText="重置"
                cancelIcon="el-icon-refresh"
                cancelType="danger"
                @click-cancel="resetSearchData"
                @click-submit="searchFn"
              />
            </ml-form>
          </div>
          <div class="assignedPeople-table-box">
            <el-table
              :data="tabberData.data"
              style="width: 100%"
              :height="200"
              @select-all="selectAll"
              @select="select"
              :row-key="row => row.userId"
              ref="assignedPeopleTableRef"
            >
              <el-table-column type="selection" :reserve-selection="true" width="55" />
              <el-table-column align="center" label="账号" prop="userCode" min-width="30%" />
              <el-table-column
                show-overflow-tooltip
                align="center"
                label="用户名"
                prop="userName"
                min-width="15%"
              />
              <el-table-column align="center" label="阿米巴" prop="companyName" min-width="30%" />
              <el-table-column
                show-overflow-tooltip
                align="center"
                label="职位"
                prop="job"
                min-width="20%"
              />
            </el-table>
            <!-- 分页 -->
            <ml-pagination
              :total="tabberData.total"
              :page="tabberData.page"
              v-model="tabberData.page"
              :size="tabberData.size"
              @current-change="currentChange"
              @size-change="sizeChange"
            />
          </div>
        </div>
      </template>
    </ml-dialog>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getTabberData, tabberHeight, searchParams, formetData } from '@/utils'

export default {
  name: 'InspectTasksAction',
  setup() {
    const { commit, getters, dispatch } = useStore()
    const router = useRouter()
    const toKen = computed(() => getters.getToken)
    /* 开始 表单 **********************************/
    // 详情数据
    const inspectTasksActionFormRef = ref()
    // 表单配置
    const inspectTasksActionForm = reactive({
      taskName: '',
      planId: '',
      date: [],
      managerUserVos: [],
      inspectUserVos: []
    })
    const inspectTasksActionFormNode = ref({})
    // 巡检计划列表
    const inspectPlansData = ref([])
    // 表单校验
    const inspectTasksActionFormRules = {
      taskName: [{ required: true, message: '请输入巡检名称 ', trigger: 'blur' }],
      planId: [{ required: true, message: '请选择巡检计划 ', trigger: 'blur' }],
      date: [{ required: true, message: '请选择巡检时间', trigger: 'blur' }],
      deviceId: [{ required: true, message: '请选择设备', trigger: 'blur' }],
      managerUserVos: [{ required: true, message: '请输入负责人', trigger: 'blur' }],
      inspectUserVos: [{ required: true, message: '请输入执行人', trigger: 'blur' }]
    }
    // 提交表单
    const clickSubmitInspectTasksAction = () => {
      inspectTasksActionFormRef.value.CustomFormRef.validate()
        .then(() => {
          const inspectTasksActionParams = searchParams(inspectTasksActionForm, ['date'])
          if (inspectTasksActionForm.date) {
            inspectTasksActionParams.startTime = formetData(inspectTasksActionForm.date[0])
            inspectTasksActionParams.endTime = formetData(inspectTasksActionForm.date[1])
          }
          dispatch('fetchAddInspectTask', inspectTasksActionParams).then(data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })
              router.push({ path: '/inspectTasks' })
            }
          })
        })
        .catch(() => {})
    }
    // 取消表单
    const clickCancelInspectTasksAction = () => {
      router.push({ path: '/inspectTasks' })
    }

    // 选择负责人列表
    const isMUser = ref('')
    const selectManagerUserVosData = ref()
    // 选择执行人列表
    const selectInspectUserVosData = ref()
    // 保存选择人
    const saveSelectManagerUserVosData = ref([])
    const saveSelectInspectUserVosData = ref([])

    const tabberData = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10,
      saveSelectManagerUserVosData: [],
      saveSelectInspectUserVosData: [],
      isMSubmie: false,
      isISubmie: false
    })

    // 获取账户列表
    const getAssignedPeopleData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(assignedPeopleForm)
      searchDataParams.userTypes =
        isMUser.value === '负责人' ? 'amiba_manager' : 'engineer,amiba_manager'
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetAppUsers',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0

      if (isMUser.value === '负责人') {
        if (selectManagerUserVosData.value.length > 0) {
          selectManagerUserVosData.value.map(item => {
            assignedPeopleTableRef.value.toggleRowSelection(item, true)
          })
        }
      } else {
        if (selectInspectUserVosData.value.length > 0) {
          selectInspectUserVosData.value.map(item => {
            assignedPeopleTableRef.value.toggleRowSelection(item, true)
          })
        }
      }
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getAssignedPeopleData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }

    watch(
      () => tabberData.saveSelectManagerUserVosData,
      newvalue => {
        selectManagerUserVosData.value = newvalue
      }
    )
    watch(
      () => tabberData.saveSelectInspectUserVosData,
      newvalue => {
        selectInspectUserVosData.value = newvalue
      }
    )

    watch(
      () => inspectTasksActionFormNode.value,
      newvalue => {
        inspectTasksActionForm.date = [newvalue.startTime, newvalue.endTime]
        inspectTasksActionForm.managerUserVos = [
          {
            userId: newvalue.createById,
            userName: newvalue.createUserName
          }
        ]
      }
    )

    // 打开指派人对话框
    const assignedPeopleDialogRef = ref()
    // 点击添加负责人
    const clickManagerUserVosAdd = () => {
      isMUser.value = '负责人'
      selectManagerUserVosData.value = saveSelectManagerUserVosData.value
      assignedPeopleForm.companyId = inspectTasksActionFormNode.value.companyId
      assignedPeopleForm.projectId = inspectTasksActionFormNode.value.projectId
      getAssignedPeopleData()
      assignedPeopleDialogRef.value.showDialog = true
    }
    // 点击添加执行人
    const clickInspectUserVosAdd = () => {
      isMUser.value = '执行人'
      selectInspectUserVosData.value = saveSelectInspectUserVosData.value
      assignedPeopleForm.companyId = inspectTasksActionFormNode.value.companyId
      // assignedPeopleForm.projectId = inspectTasksActionFormNode.value.projectId
      getAssignedPeopleData()
      assignedPeopleDialogRef.value.showDialog = true
    }

    // 指派人表单
    const assignedPeopleTableRef = ref()
    const assignedPeopleFormRef = ref()
    // 指派人搜索条件
    const assignedPeopleForm = reactive({
      userCodeLike: '',
      userNameLike: '',
      companyId: ''
      // projectId: ''
    })

    // 重置搜索内容
    const resetSearchData = () => {
      assignedPeopleFormRef.value.CustomFormRef.resetFields()
      tabberData.page === 1 ? getAssignedPeopleData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getAssignedPeopleData() : (tabberData.page = 1)
    }

    // 选中的添加到列表
    const getSelection = selection =>
      selection.map(item => {
        return {
          userId: item.userId,
          userName: item.userName
        }
      })
    // 选中单个
    const select = selection => {
      if (isMUser.value === '负责人') {
        selectManagerUserVosData.value = getSelection(selection)
      } else {
        selectInspectUserVosData.value = getSelection(selection)
      }
    }
    // 选中全部
    const selectAll = selection => {
      if (isMUser.value === '负责人') {
        selectManagerUserVosData.value = selectManagerUserVosData.value = getSelection(selection)
      } else {
        selectInspectUserVosData.value = selectInspectUserVosData.value = getSelection(selection)
      }
    }

    // 提交选中指派人
    const clickSubmitAssignedPeople = () => {
      if (isMUser.value === '负责人') {
        tabberData.isMSubmie = true
        saveSelectManagerUserVosData.value = selectManagerUserVosData.value
        inspectTasksActionForm.managerUserVos = selectManagerUserVosData.value
      } else {
        tabberData.isISubmie = true
        saveSelectInspectUserVosData.value = selectInspectUserVosData.value
        inspectTasksActionForm.inspectUserVos = selectInspectUserVosData.value
      }

      assignedPeopleDialogRef.value.showDialog = false
      assignedPeopleTableRef.value.clearSelection()
    }
    // 取消选择指派人
    const clickSubmitAssignedPeopleClose = () => {
      if (isMUser.value === '负责人') {
        if (!tabberData.isMSubmie) {
          selectManagerUserVosData.value = []
        }
        tabberData.isMSubmie = false
      } else {
        if (!tabberData.isISubmie) {
          selectInspectUserVosData.value = []
        }
        tabberData.isISubmie = false
      }
      assignedPeopleTableRef.value.clearSelection()
    }
    // 点击遮罩关闭
    const beforeClose = () => {
      assignedPeopleTableRef.value.clearSelection()
    }

    // 删除Tag
    const clickTagCloseM = index => {
      saveSelectManagerUserVosData.value.splice(index, 1)
      selectManagerUserVosData.value = []
      assignedPeopleTableRef.value.clearSelection()
    }
    const clickTagCloseI = index => {
      saveSelectInspectUserVosData.value.splice(index, 1)
      selectInspectUserVosData.value = []
      assignedPeopleTableRef.value.clearSelection()
    }

    // 获取列表
    const getInspectPlans = async (params = {}) => {
      const { data = [] } = await getTabberData(dispatch, 'fetchGetInspectPlans', params)
      inspectPlansData.value = data || []
    }

    /* 结束 用户表单 **********************************/
    onMounted(() => {
      getInspectPlans()
    })
    return {
      inspectTasksActionForm,
      inspectTasksActionFormRules,
      clickSubmitInspectTasksAction,
      clickCancelInspectTasksAction,
      tabberHeight,
      inspectTasksActionFormRef,
      toKen,
      assignedPeopleDialogRef,
      assignedPeopleForm,
      assignedPeopleFormRef,
      clickTagCloseM,
      clickTagCloseI,
      tabberData,
      assignedPeopleTableRef,
      currentChange,
      sizeChange,
      resetSearchData,
      searchFn,
      select,
      selectAll,
      inspectPlansData,
      clickManagerUserVosAdd,
      clickInspectUserVosAdd,
      isMUser,
      clickSubmitAssignedPeople,
      clickSubmitAssignedPeopleClose,
      beforeClose,
      inspectTasksActionFormNode
    }
  }
}
</script>

<style lang="scss">
.inspect-tasks-action {
  position: relative;

  .name-tag-box {
    border: 1px solid $--border-color-base;
    border-radius: 4px;
    padding: 0 5px;
    min-height: 40px;
    max-height: 110px;
    overflow-x: hidden;
    .el-tag {
      margin-right: 5px;
    }
  }
  .assignedPeople-select {
    .el-input__inner {
      width: 150px;
    }
  }

  .assignedPeople-table-box {
    border-top: 1px solid $--border-color-lighter;
    padding-top: 10px;
  }
}
</style>
